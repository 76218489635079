import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import { sizes } from "../device"

const Background = () => {
  const data = useStaticQuery(graphql`
    query {
      desktopBg: file(relativePath: { eq: "services/bg-desktop.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      mobileBg: file(relativePath: { eq: "services/bg-mobile.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const sources = [
    data.mobileBg.childImageSharp.fluid,
    {
      ...data.desktopBg.childImageSharp.fluid,
      media: `(min-width: ${sizes.small}px)`,
    },
  ]

  return <Img fluid={sources} objectFit="cover" objectPosition="top" />
}

export default Background
