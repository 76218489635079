import React from "react"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"

import plus from "../../images/services/add.svg"
import cross from "../../images/services/delete.svg"
import PlaceholderImage from "../noResults"
import Slider from "./services-slider"

const Wrapper = styled.div``

const List = styled.div`
  margin: 2rem 0;
  .list-item {
    margin: 2rem auto 0 auto;
    width: var(--spread);
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--grey);
    padding: 0.7rem 0;
    position: relative;
    &.selected {
      span {
        color: var(--green);
      }
    }
    .cat-img {
      width: 30px;
    }
    span {
      margin-left: 0.5rem;
      font-family: "Theano Didot";
      text-transform: uppercase;
      font-size: 1rem;
    }
    .expander {
      align-self: center;
      display: block;
      padding: 0 1rem;
      position: absolute;
      right: 0;
      img {
        width: 8px;
      }
    }
  }
`

const ServicesMobile = ({ services, handleClick, selected }) => {
  return (
    <Wrapper>
      <List>
        {services.map((item, index) => (
          <div key={index}>
            <div
              className={
                index === parseInt(selected)
                  ? "list-item selected"
                  : "list-item"
              }
              onClick={handleClick}
              data-index={index}
            >
              {item.image ? (
                <Img
                  className="cat-img"
                  fluid={item.image.localFile.childImageSharp.fluid}
                  alt={item.image.alt_text}
                />
              ) : (
                <PlaceholderImage className="cat-img" />
              )}
              <span>{item.category}</span>
              <span className="expander">
                <img
                  src={index === parseInt(selected) ? cross : plus}
                  alt="Expander"
                />
              </span>
            </div>
            {index === parseInt(selected) && item.posts.length ? (
              <Slider posts={item.posts} />
            ) : null}
          </div>
        ))}
      </List>
    </Wrapper>
  )
}

export default ServicesMobile
