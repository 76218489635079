import React from "react"
import styled from "styled-components"
import device from "./device"
import error from "../images/404.svg"

const Message = styled.div`
  font-size: 3rem;
  color: rgb(200, 200, 200);
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  padding: 2rem;
  ${device.small`font-size: 2rem;`}
`

export default function NoResults({ text }) {
  return <Message>{text}</Message>
}

export const PlaceholderImage = ({ className, style }) => {
  return <img src={error} alt="Not found" className={className} style={style} />
}
