import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import { ReserveNow, LearnMore } from "../hooks/useLinks"
import useViewport from "../hooks/useViewport"
import device, { sizes } from "../device"
import Background from "./services-image"
import GreenHeading from "../heading"
import NoResults, { PlaceholderImage } from "../noResults"
import Slider from "./services-slider"
import ServicesMobile from "./services-mobile"

const Wrapper = styled.div`
  position: relative;
  .container {
    display: flex;
    justify-content: center;
    ${device.small`display: block; width: var(--spread); margin: 0 auto;`}
  }
  .heading {
    font-size: 2.5rem;
    line-height: 0.8;
    ${device.small`font-size: 2rem;`}
    .secondary-heading {
      margin-left: 3rem;
      ${device.small`margin-left: 2rem;`}
    }
  }
  .services-bg {
    background-color: var(--darkgrey);
    margin-top: -30%;
    position: relative;
    z-index: -1;
    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
    }
    ${device.small`margin-top: 0; height: 800px;`}
  }
  .links {
    font-size: 0.7rem;
    text-align: center;
    a {
      margin: 0 0.5rem;
    }
    .learn-more {
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      color: var(--black);
      padding: calc(0.5rem - 1px) 1.5rem;
      border: 1px solid var(--grey);
    }
  }
`

const Categories = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  flex-wrap: wrap;
  .category {
    margin: 0 1.5rem;
    display: flex;
    align-items: center;
    font-size: 0.7rem;
    font-family: "Theano Didot";
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;
    .cat-img {
      filter: var(--black-filter);
      margin-right: 0.3rem;
      width: 35px;
    }
    span {
      padding: 1px 8px;
    }
    &.selected {
      span {
        color: var(--green);
        border-bottom: 3px solid var(--green);
      }
      .cat-img {
        filter: var(--green-filter);
      }
    }
  }
`

const Services = ({ preActive }) => {
  const isMobile = useViewport() <= sizes.small

  const data = useStaticQuery(graphql`
    {
      allWordpressWpServicesCategories {
        edges {
          node {
            name
            acf {
              image {
                alt_text
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 35) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            services {
              title
              featured_media {
                alt_text
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 300) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              acf {
                text
                link
              }
            }
          }
        }
      }
    }
  `)

  const services = data.allWordpressWpServicesCategories.edges.map(
    ({ node }) => ({
      category: node.name,
      image: node.acf ? node.acf.image : null,
      posts: node.services,
    })
  )

  let preActiveIndex = 0
  if (preActive) {
    preActiveIndex = services.findIndex(ele => ele.category === preActive)
  }

  const [selected, setSelected] = useState(preActiveIndex)

  function handleClick(e) {
    const target = parseInt(e.currentTarget.getAttribute("data-index"))
    if (isMobile && selected === target) {
      setSelected(null)
    } else {
      setSelected(target)
    }
  }

  useEffect(() => {
    isMobile ? setSelected(null) : setSelected(preActiveIndex)
  }, [isMobile])

  return (
    <Wrapper>
      <div className="container">
        <h2 className="heading">
          <GreenHeading text="Our" />
          <br />
          <span className="secondary-heading">Services</span>
        </h2>
      </div>

      {isMobile || selected === null ? (
        <ServicesMobile
          services={services}
          handleClick={handleClick}
          selected={selected}
          setSelected={setSelected}
        />
      ) : (
        <>
          <Categories>
            {services.map((item, index) => (
              <div
                className={
                  index === selected ? "category selected" : "category"
                }
                key={index}
                onClick={handleClick}
                data-index={index}
              >
                {item.image ? (
                  <Img
                    className="cat-img"
                    fluid={item.image.localFile.childImageSharp.fluid}
                    alt={item.image.alt_text}
                  />
                ) : (
                  <PlaceholderImage className="cat-img" />
                )}
                <span>{item.category}</span>
              </div>
            ))}
          </Categories>
          {services[selected].posts.length ? (
            <>
              <Slider posts={services[selected].posts}></Slider>
              <div className="links">
                <LearnMore className="learn-more" />
                <ReserveNow />
              </div>
            </>
          ) : (
            <NoResults text={"No services found"} />
          )}
        </>
      )}

      <div className="services-bg">
        <Background />
      </div>
    </Wrapper>
  )
}

export default Services
